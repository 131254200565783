
.side-menu {
    /* width: 25%; */
    background-color: black;
    padding: 4px;
    display: none;
    left: -250px;
    width: 250px;
    padding: 10px;
    display: inline;
  }
  
  .chat-box {
    flex: 1;
    position: relative;
    /* background: linear-gradient(to top, #798ee2, #7aa69e, #acbbff, #3589ff); */
    /* background: linear-gradient(to top, #b0bbe7, #f9ebff, #cccce7, #c9dfff); */
    /* background: linear-gradient(to top, #8aa2ff, #c9fcff, #a5afff, #5fa0ff); */
    /* background: linear-gradient(0deg, #1c6bcf, #9d80bb, #02528399, #764ba2, #1cbccf); */
    background: linear-gradient(325deg, #2a67b5, #9d80bb, #02528399, #c185ff, #1cbccf);
    z-index: 110;
  }
  
  .chat-log {
    margin-top: 75px;
    text-align: left;
    margin-left: 11px;
  }
  
  .circular-progress {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .mobile-new-chat-button {
    position: static;
    top: 0px;
    z-index: 200;
  }
  
  .white-buttons {
    display: flex;
    position: fixed;
    bottom: 0px;
    background-color:  black;/*#202123;*/
    z-index: 90;
    width: 240px;
    height: 276px;
  }
  
  
  
  @media (min-width: 500px) {
    .mobile-new-chat-button {
      display: none;
    }
  
    .mobile-login-button {
      display: none;
    }
  
    .mobile-store-button {
      display: none;
    }
    .card {
      background-color: #353740;
    }
  }
  
  @media (max-width: 900px) {
    .chat-log {
      margin-top: 65px;
      text-align: left;
      margin-left: 11px;
    }
  }
  
  .response-separator{
    margin-top: 30px;
  }
  
  .side-menu.hidden {
    display: none;
  }
  
  .generals {
    padding-bottom: 15px;
    font-size: 25px;
    font-weight: 700px;
    color: #353740;
  }
  
  
  
  
  
  
  
  
  /* Floating Button Style */
  .floating-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000; /* Make sure it's above other content */
    background-color: #353740; /* Dark background for the button */
    color: white;
    border: none;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    font-size: 30px;
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  }
  
  /* Overlay Style */
  .menu-overlay {
    display: none; /* Hidden by default */
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8); /* Black background with opacity */
    z-index: 999; /* Below the button, above other content */
  }
  
  /* Show the overlay when active */
  .menu-overlay.active {
    display: block;
  }
  
  .menu-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%; /* Adjust based on preference */
    height: 80%;
    overflow-y: auto; /* Scrollable content */
  }
  
  .menu-container-2 {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;  /* Keeps buttons evenly spaced */
    padding: 10px;
  }
  
  
  .menu-button {
    padding: 10px 14px;
    margin: 5px;
    font-size: 16px;  /* Standard font size for all devices */
    color: white;
    background: linear-gradient(to right, #000000, #15002c);
    border: none;
    border-radius: 20px;
    cursor: pointer;
    outline: none;
    transition: background 0.3s ease, transform 0.2s ease;
    flex: 0 1 auto;  /* Allow flexible grow and shrink but initially based on content */
    text-align: center;
    box-sizing: border-box;  /* Includes padding and border in the element's total width and height */
  }
  
  .menu-button:hover {
    background: linear-gradient(to right, #42e695, #3bb2b8);
    transform: scale(1.05);
  }
  
  /* Media Query for small devices */
  @media (max-width: 600px) {
    .menu-button {
      font-size: 14px;  /* Smaller font size for better readability on small screens */
      flex: 1 1 45%;  /* Flex basis of 45% to allow two buttons per row on mobile */
    }
  }
  
  
  /* Media Query for small devices */
  @media (max-width: 600px) {
    .menu-container-2 {
      display: none;
    }
    .menu-button {
      font-size: 14px; 
      flex: 1 1 45%;
    }
  }