.birthdate-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    background-color: #2c2c2e; /* Your dark background color */
    padding: 20px;
    border-radius: 10px; /* Match your style */
  }
  
  .input-field, .select-field {
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: #3a3a3c; /* Slightly lighter than the form background for contrast */
    color: white;
  }
  
  .submit-button-persona {
    padding: 10px;
    background-color: #505050; /* Adjust color to match your theme */
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .submit-button-persona:hover {
    background-color: #686868; /* Hover state color */
  }

  .submitting {
    background-color: #ccc;
    color: #666;
    cursor: wait;
  }
  