@import url('https://fonts.googleapis.com/css2?family=Cairo&display=swap');

:root {
  --color-white-100: hsl(206, 5%, 98%);
  --color-white-200: hsl(206, 5%, 90%);
  --color-white-300: hsl(206, 5%, 80%);
  --color-white-400: hsl(206, 5%, 65%);
  --color-white-500: hsl(206, 5%, 50%);
  --color-white-600: hsl(206, 5%, 35%);
  --color-black-100: hsl(213, 20%, 9%);
  --color-black-200: hsl(213, 23%, 8%);
  --color-black-300: hsl(214, 21%, 6%);
  --color-black-400: hsl(210, 21%, 6%);
  --color-black-500: hsl(216, 22%, 4%);
  --color-black-600: hsl(220, 18%, 3%);
  --color-black-700: hsl(220, 27%, 2%);
  --color-black-800: hsl(180, 20%, 1%);
  --color-blue-100: hsl(214, 95%, 93%);
  --color-blue-200: hsl(213, 97%, 87%);
  --color-blue-300: hsl(212, 96%, 78%);
  --color-blue-400: hsl(213, 94%, 68%);
  --color-blue-500: hsl(217, 91%, 60%);
  --color-blue-600: hsl(221, 83%, 53%);
  --color-blue-700: hsl(224, 76%, 48%);
  --color-blue-800: hsl(226, 71%, 40%);
  --color-blue-900: hsl(224, 64%, 33%);
  --shadow-small: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
     0 1px 2px 0 rgba(0, 0, 0, 0.06);
  --shadow-medium: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
     0 2px 4px -1px rgba(0, 0, 0, 0.06);
  --shadow-large: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
     0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  list-style: none;
  list-style-type: none;
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

html {
  font-size: 100%;
  box-sizing: inherit;
  scroll-behavior: smooth;
  height: -webkit-fill-available;
}

body {
  font-family: 'Cairo', sans-serif;
} 
 
body {
 
  font-size: clamp(1rem, 2vw, 1.125rem);
  font-weight: 400;
  line-height: 1.5;
  height: -webkit-fill-available;
  color: var(--color-white-100);
  background-color: #201818;
}

body {
  background-image: url('https://storage.googleapis.com/assets-sites/963/background.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

main {
  overflow: hidden;
}

a,
button {
  cursor: pointer;
  border: none;
  outline: none;
  user-select: none;
  background: none;
  box-shadow: none;
  text-decoration: none;
}

img,
video {
  display: block;
  max-width: 100%;
  height: auto;
  object-fit: cover;
}

.section {
  margin: 0 auto;
  padding: 6rem 0 1rem;
  margin-bottom: 50px;
}

.container {
  max-width: 75rem;
  height: auto;
  margin: 0 auto;
  padding: 0 2rem;
}

.centered {
  text-align: center;
  vertical-align: middle;
  margin-bottom: 1rem;
}

.heading-xl {
  font-family: inherit;
  font-size: clamp(1rem, 5vw, 3.0rem);
  font-weight: 700;
  line-height: 1.15;
  letter-spacing: -1px;
  color: #201818;
}
.heading-lg {
  font-family: inherit;
  font-size: clamp(1.2rem, 5vw, 3.176rem);
  font-weight: 700;
  line-height: 1.15;
  letter-spacing: -1px;

}
.heading-md {
  font-family: inherit;
  font-size: clamp(1.794rem, 5vw, 2.379rem);
  font-weight: 700;
  line-height: 1.25;
  letter-spacing: -1px;
}
.heading-sm {
  font-family: inherit;
  font-size: clamp(1.476rem, 4vw, 1.782rem);
  font-weight: 600;
  line-height: 1.5;
 
}
.heading-xs {
  font-family: inherit;
  font-size: clamp(1.215rem, 2vw, 1.335rem);
  font-weight: 500;
  line-height: 1.5;  


}

.paragraph {
  font-family: inherit;
  font-size: 1.4rem;
  line-height: inherit;
  max-width: 95%;
  height: auto;
  text-transform: unset;
  color: #201818;
}

.btn {
  display: inline-block;
  font-family: inherit;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  user-select: none;
  outline: none;
  border: none;
  border-radius: 0.25rem;
  text-transform: unset;
  transition: all 0.3s ease-in-out;
}
.btn-inline {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  column-gap: 0.5rem;
}
.btn-darken {
  padding: 0.4rem 1rem;
  color: var(--color-black-200);
  background-color: var(--color-white-100);
  box-shadow: var(--shadow-medium);
  border-radius: 1rem;
  margin-right: 8px;
}
.btn-neutral {
  padding: 0.75rem 2rem;
  color: var(--color-black-500);
  background-color: var(--color-white-100);
  box-shadow: var(--shadow-medium);
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: auto;
  margin: 0 auto;
  transition: all 0.35s ease;
}
.header.on-scroll {
  background:  rgba(255, 255, 255, 0.5);
  box-shadow: var(--shadow-medium);
}

.navbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  column-gap: 1rem;
  width: 100%;
  height: 4.25rem;
  margin: 0 auto;
}

.brand {
  font-family: inherit;
  font-size: 1.625rem;
  font-weight: 600;
  line-height: 1.5;
  letter-spacing: -1px;
  color: black;
  text-transform: uppercase;
  height:50px;
}

.menu {
  position: fixed;
  top: -100%;
  left: 0;
  width: 100%;
  height: auto;
  padding: 4rem 0 3rem;
  overflow: hidden;
  background-color: transparent;
  box-shadow: var(--shadow-medium);
  transition: all 0.4s ease-in-out;
}
.menu.is-active {
  top: 0;
  width: 100%;
  height: auto;
}
.menu-inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 1.25rem;
}
.menu-link {
  font-family: inherit;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
  color: #201818;
  text-transform: uppercase;
  transition: all 0.3s ease;
}
.menu-block {
  display: inline-block;
  font-family: inherit;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.25;
  user-select: none;
  white-space: nowrap;
  text-align: center;
  margin-left: auto;
  padding: 0.65rem 1.5rem;
  border-radius: 1rem;
  text-transform: capitalize;
  color: var(--color-white);
  background-color: var(--color-blue-600);
  box-shadow: var(--shadow-medium);
  transition: all 0.3s ease-in-out;
}


.menu-block:hover {
    background-color: var(--color-blue-800);
    transform: translateY(-2px);
  }
  
  .menu-block:hover::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -6px;
    width: 100%;
    height: 2px;
    background-color: var(--color-white);
    transform: scaleX(0);
    transition: transform 0.2s ease-in-out;
  }
  
  .menu-block:hover::after {
    transform: scaleX(1);
  }
  


@media only screen and (min-width: 48rem) {
  .menu {
    position: relative;
    top: 0;
    width: auto;
    height: auto;
    padding: 0rem;
    margin-left: auto;
    background: none;
    box-shadow: none;
  }
  .menu-inner {
    display: flex;
    flex-direction: row;
    column-gap: 2rem;
    margin: 0 auto;
  }
  .menu-link {
    text-transform: capitalize;
  }
  .menu-block {
    margin-left: 5px;
  }
}

.burger {
  position: relative;
  display: block;
  cursor: pointer;
  user-select: none;
  order: -1;
  z-index: 10;
  width: 1.75rem;
  height: 1rem;
  border: none;
  outline: none;
  background: none;
  visibility: visible;
  transform: rotate(0deg);
  transition: 0.35s ease;
}
@media only screen and (min-width: 48rem) {
  .burger {
    display: none;
    visibility: hidden;
  }
}
.burger-line {
  position: absolute;
  display: block;
  right: 0;
  width: 100%;
  height: 2px;
  border: none;
  outline: none;
  opacity: 1;
  transform: rotate(0deg);
  background-color: var(--color-black-100);
  transition: 0.25s ease-in-out;
}
.burger-line:nth-child(1) {
  top: 0px;
}
.burger-line:nth-child(2) {
  top: 0.5rem;
}
.burger-line:nth-child(3) {
  top: 1rem;
}
.burger.is-active .burger-line:nth-child(1) {
  top: 0.5rem;
  transform: rotate(135deg);
}
.burger.is-active .burger-line:nth-child(2) {
  right: -1.5rem;
  opacity: 0;
  visibility: hidden;
}
.burger.is-active .burger-line:nth-child(3) {
  top: 0.5rem;
  transform: rotate(-135deg);
}

.banner-column {
  position: relative;
  display: grid;
  align-items: center;
  row-gap: 3rem;
}
@media only screen and (min-width: 48rem) {
  .banner-column {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    justify-content: center;
  }
}
@media only screen and (min-width: 64rem) {
  .banner-column {
    grid-template-columns: 1fr max-content;
    column-gap: 4rem;
    margin-top: 3rem;
  }
}
.banner-image {
  display: flex;
  max-width: 15rem;
  height: auto;
  margin-top: 2rem;
  object-fit: cover;
  justify-self: center;
}
@media only screen and (min-width: 48rem) {
  .banner-image {
    order: 2;
    max-width: 20rem;
    height: auto;
    
  }
}
@media only screen and (min-width: 64rem) {
  .banner-image {
    max-width: 15rem;
    height: auto;
    margin-right: 5rem;
    order: 2;
    
  }
}
.banner-inner {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 1.75rem;
  
}
.banner-links {
  position: absolute;
  top: 30%;
  right: 1.5rem;
  display: grid;
  justify-items: center;
  row-gap: 0.5rem;
  opacity: 0;
  visibility: hidden;
}
@media only screen and (min-width: 64rem) {
  .banner-links {
    opacity: 1;
    visibility: visible;
  }
}
.banner-links > * {
  font-size: 1.25rem;
  line-height: 1.25;
  color: var(--color-white-100);
}
.banner-links::before {
  position: absolute;
  content: "";
  top: -3rem;
  width: 4rem;
  height: 1.5px;
  transform: rotate(90deg);
  background: var(--color-white-100);
}
.banner-links::after {
  position: absolute;
  content: "";
  bottom: -3rem;
  width: 4rem;
  height: 2px;
  transform: rotate(90deg);
  background: var(--color-white-100);
}


@media only screen and (max-width: 767px) {
  .banner-image {
   height: 380px;
   min-width: 185px;
   order: 3;
  }
}


@media only screen and (max-width: 1300px) {
    .banner-inner {
        margin-top: 3rem;
        order: 1;
      }
  }

@media only screen and (max-width: 767px) {
  .banner-inner {
    margin-top: 3rem;
    order: 1;
  }
}
@media only screen and (max-width: 1300px) {
    body {
      background-size: auto 100%;
      background-position: center top;
    }
  }


  @media only screen and (max-width: 767px) {
    .paragraph {
       
        font-size: clamp(1.2rem, 2vw, 1.125rem);
        
      }
  }



  @media only screen and (max-width: 1060px) {
    .heading-xl{
       
        font-size: clamp(2rem, 5vw, 5rem);
        
    }
        
      
      
  }

  @media only screen and (max-width: 780px) {
    .heading-xl{
       
        font-size: clamp(2rem, 6vw, 5rem);
        
    }
        
      
      
  }


  @media only screen and (max-width: 780px) {
    
    .pricing {
      margin-left: '2%';
    }

    .menu.is-active {
       color: #E9E9E9;
       background-color: #E9E9E9;
      }
      
      
  }



  .btn-darken:hover {
    background-color: var(--color-black-800);
    transform: translateY(-2px);
    color: var(--color-white-100);
  }
  
  .btn-darken:hover::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -6px;
    width: 100%;
    height: 2px;
    background-color: var(--color-white);
    transform: scaleX(0);
    transition: transform 0.2s ease-in-out;
  }
  
  .menu-block:hover::after {
    transform: scaleX(1);
  }
  

  
  .banner-2{
    width: 60%;
    color: black;
    text-align: center; 
  }
 

  .btn-google {
    display: inline-block;
    font-size: 1rem;
    font-weight: bold;
    padding: 1.35rem 5.8rem;
    border-radius: 0.8rem;
    background-color:white;
    color: black;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    margin: 0 auto;
  }
  
  .btn-google:hover {
    background-color: black;
    color: white;
  }
  
  .btn i {
    margin-right: 0.5rem;
  }

  .g-icon{
    width: 12px;
    margin-right: 0.5rem;
  }


  .absolutePosition {
    position: absolute !important;
    top: 19px !important;
    right: 27px !important;
  }

  .customer-reviews {
    display: flex;
    flex-direction: row; /* Align items horizontally */
    align-items: center; /* Center items vertically */
    justify-content: space-between; /* Distribute space between the elements */
    background-color: #000; /* Your background color */
    color: #fff; /* Your text color */
    padding: 10px;
    border-radius: 10px; /* Round the corners of the customer reviews section */
    margin-top: 20px; /* Add space above the customer reviews section */
  }
  
  .customer-images {
    display: flex; /* Ensure this is a flex container to handle the images in a row */
  }
  
  .customer-images img {
    border-radius: 50%;
    border: 2px solid #fff; /* Add border to separate overlapping images */
    margin-right: -15px; /* Adjust as needed to create the overlapping effect */
    width: 50px; /* Define a size for the images */
    height: 50px; /* Define a size for the images */
  }
  
  .rating {
    display: flex;
    align-items: center; /* Center the stars and text vertically */
    gap: 5px; /* Add a gap between the stars and the text */
  }
  
  .stars {
    margin-left: 20px;
    color: #ff0; /* Star color */
  }
  
  .text {
    display: flex; /* Make the text and the heart icon align horizontally */
    align-items: center; /* Align the text and icon vertically */
    gap: 5px; /* Add a gap between the text and the heart icon */
  }
  
  .heart {
    color: #a33; /* Heart color or use an actual heart icon */
  }

  .special-p {
    font-size: 0.9rem;
    font-style: italic;
  }


  .main {
    display: flex;
    flex-direction: column;
    min-height: 100vh; /* Make sure the container is at least the height of the viewport */
  }
  
  /* Style your footer */
  .footer {
    margin-top: auto; /* Pushes the footer to the bottom */
    width: 100%;
    background-color: #333; /* Footer background color */
    color: white; /* Footer text color */
    text-align: center;
    padding: 20px 0; /* Add some padding */
  }
  
  .footer a,
  .footer span {
    text-decoration: none;
    color: white;
    padding: 0 10px;
  }
  
  .footer a:hover {
    text-decoration: underline;
  }
  
  .footer .footer-socials {
    display: inline-block;
    margin: 0 10px;
  }
  
  .footer .footer-socials a {
    padding: 0 5px;
  }
  
  .footer .footer-legal {
    margin-top: 10px; /* Space from social icons */
    display: block; /* Makes the content center-aligned */
    font-size: 0.8em;
  }
  
  @media (max-width: 768px) {
    .footer-container {
      flex-direction: column;
      text-align: center;
    }
  
    .footer-nav, .footer-socials {
      margin-top: 10px;
      justify-content: center;
    }
  }

  .platform {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 50px 20px; /* Adds padding around the content */
    background-color: #f2f2f2; /* Light grey background, change as needed */
  }
  
  .platform-h2 {
    font-size: 2rem; /* Larger font size for the heading */
    margin-bottom: 80px; /* Space between the heading and the image */
    color: #333; /* Dark grey color for the text */
  }
  
  .banner-platform {
    max-width: 300px;
    max-width: 100%; /* Ensures the image is responsive and doesn't overflow its container */
    height: auto; /* Maintain the aspect ratio of the image */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: adds a subtle shadow for depth */
    border-radius: 8px; /* Optional: rounds the corners of the image */
  }


  @media (max-width: 500px) {
    .banner-platform {
      max-width: 280px!important; /* Ensures the image is responsive and doesn't overflow its container */
      height: auto; /* Maintain the aspect ratio of the image */
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: adds a subtle shadow for depth */
      border-radius: 8px; /* Optional: rounds the corners of the image */
    }
  }

  .pricing {
    margin-left: '15%';
  }
  