.mode-changer-container {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  position: fixed;
  bottom: 170px;
  left: 40px;
}

.text-mode-button {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #7966f6;
  border-radius: 10px;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  width: 200px;
  background: linear-gradient(265.27deg, #0000 20.55%, #7d91ff 94.17%);
  background-color: #6851ff;
  transition: all 150ms ease-in-out;
  color: white;
  font-weight: 600;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-size: 16px;
}

.text-mode-button:hover {
  scale: 1.04;
  background-color: #9f4cff;
  border-color: #9f4cff;
  cursor: pointer;
}

.text-mode-button:active {
  opacity: 0.2;
}

.text-mode-button:disabled {
  opacity: 0.5;
  scale: none;
  background-color: #6851ff;
}

.code-mode-svg {
  margin-right: 5px;
  width: 27px;
  height: 27px;
  margin-top: -3px;
  margin-bottom: -3px;
}

.text-mode-svg {
  margin-right: 5px;
  width: 23px;
  height: 27px;
  margin-top: -3px;
  margin-bottom: -3px;
}

.alpha-warning {
  position: fixed;
  bottom: 210px;
  left: 88px;
  font-size: 12px;
  z-index: 100;
  color: rgb(255, 105, 105);
}
