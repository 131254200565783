.new-chat-button {
  padding: 6px;
  border: 1px solid #474749;
  border-radius: 5px;
  text-align: center;
  position: static;
  top: 6px;
  background: linear-gradient(265.27deg, #0000 20.55%, #202123 94.17%);
  background-color: #202123;
  transition: all 150ms ease-in-out;
  color: white;
  width: 100%;
  z-index: 400;
}

.new-chat-button span {
  padding-left: 2px;
  padding-right: 4px;
  font-size: larger;
  display: none;
}

.new-chat-button:hover {
  background-color: rgba(107, 114, 128, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.3);
  scale: 1.04;
  cursor: pointer;
}

.new-chat-button:active {
  opacity: 0.2;
}

.new-chat-button:disabled {
  opacity: 0.5;
  scale: 1;
  border: 1px solid #474749;
  background-color: #202123;
}

@media (min-width: 1024px) {
  .new-chat-button span {
    padding-left: 6px;
    padding-right: 12px;
    display: inline;
  }

  .new-chat-button {
    padding: 12px;
    text-align: left;
    top: 12px;
    font-size: 16px;
  }
}
