.store-button-container {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
}

.store-link {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: white;
  font-weight: 600;
  margin-top: 40px;
  transition: all 150ms ease-in-out;
  background: linear-gradient(265.27deg, #0000 20.55%, #b3befe 94.17%);
  background-color: #7966f6;
  border: 1px solid #7966f6;
  border-radius: 10px;
  padding: 5px;
  padding-left: 15px;
  padding-right: 15px;
}

.store-link:hover {
  scale: 1.04;
  background-color: #9f4cff;
}

.store-link:active {
  opacity: 0.2;
}

.store-button-svg {
  color: white;
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

@media (min-width: 1024px) {
  .store-link {
    margin-top: 20px;
    padding-left: 63px;
    padding-right: 64px;
    font-size: 16px;
  }

  .store-button-container {
    position: fixed;
    bottom: 70px;
    left: 40px;
  }
}
