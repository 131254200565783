.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure it's on top of other content */
  }
  
  .modal-content {
    background: #2c2c2e;
    border-radius: 10px;
    padding: 20px;
    position: relative;
    width: 90%;
    max-width: 500px; /* Adjust as needed */
  }
  
  .modal-close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    background: none;
    color: white;
    font-size: 24px;
    cursor: pointer;
  }