.response-failed-error-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
  margin-top: 20px;
}

.response-failed-error {
  color: rgb(255, 105, 105);
}

@media (min-width: 1024px) {
  .response-failed-error-container {
    font-size: 20px;
    margin-top: 30px;
  }
}
