.hero-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80%;
  flex-direction: column;
}

.info-container {
  display: flex;
  flex-direction: column;
}

.info-heading {
  margin-left: 0px;
  margin-right: 0px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.hero-svg {
  margin-right: 10px;
}

.info-sub-container {
  display: flex;
  flex-direction: column;
}

@media (min-width: 1024px) {
  .info-sub-container.side-container {
    animation: fadeInFromTop 0.8s ease-in-out forwards;
  }

  .info-heading {
    margin-top: 50px;
    margin-left: 40px;
    margin-right: 40px;
    flex-direction: column;
  }

  .info-container {
    flex-direction: row;
  }

  .hero-heading {
    font-size: 2em;
  }

  .hero-svg {
    margin-right: 0px;
  }

  .hero-container {
    height: 70%;
  }

  .side-container {
    margin-top: 40px;
  }
}

@keyframes fadeInFromTop {
  from {
    opacity: 0.5;
    transform: translateY(-40px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
