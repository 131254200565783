.sign-up-heading {
  font-size: 1.4em;
  transition: all 0.2s ease-in-out;
}

.sign-up-heading-container {
  display: none;
  align-items: center;
  justify-content: center;
}

.sign-up-heading:hover {
  cursor: pointer;
  scale: 1.06;
  text-decoration: underline;
}

.sign-up-heading:active {
  opacity: 0.2;
}

@media (min-width: 1024px) {
  .sign-up-heading {
    font-size: 2em;
  }

  .sign-up-heading-container {
    display: flex;
    flex-direction: column;
  }
}
